//jshint esversion:6

import('./site/cliente');
import('./site/main');
//import "./tags/main";
import('vila-rica-boletos');

(function($, window, document){
	$(async function() {
		if (0 != $('[name=formFiltros]').length) {
			//import('./filtros/main');
		}
		if (0 != $('.imoveis-destaque').length) {			
			//import('./destaques/main');
		}
		if (0 != $('ul.resultado-busca-imoveis').length) {			
			//import('./busca/main');
		}
		if (0 != $('ul.resultado-lancamento-imoveis').length) {			
			//import('./lancamentos/main');
		}
		if (0 != $('ul.resultado-empreendimentos-imoveis').length) {			
			//import('./empreendimentos/main');
		}
		if (0 != $('ul.resultado-investidores-imoveis').length) {			
			import('./investidores/main');
		}
		if (0 != $('ul.imoveis-semelhantes').length) {			
			//import('./semelhantes/main');
		}
		if (0 != $('a.segunda-via-doc').length) {
			import('./segunda-via-doc/main');
		}
		if (0 != $('.vue-match').length) {
			import('./match/index');
		}
	});
}(jQuery, window, document));

import * as ProximitySearch from "@diasfs/proximity-search"
import haversine from 'haversine-distance'


let icons = {
	'amenity:arts_centre': 'https://maps.gstatic.com/mapfiles/place_api/icons/art_gallery-71.png',
	'aeroway:aerodrome': 'https://maps.gstatic.com/mapfiles/place_api/icons/airport-71.png',
	'amenity:atm': 'https://maps.gstatic.com/mapfiles/place_api/icons/atm-71.png',
	'amenity:bank': 'https://maps.gstatic.com/mapfiles/place_api/icons/bank_dollar-71.png',
	'amenity:bar': 'https://maps.gstatic.com/mapfiles/place_api/icons/bar-71.png',
	'amenity:bus_station': 'https://maps.gstatic.com/mapfiles/place_api/icons/bus-71.png',
	'public_transport:station': 'https://maps.gstatic.com/mapfiles/place_api/icons/bus-71.png',
	'amenity:cafe': 'https://maps.gstatic.com/mapfiles/place_api/icons/cafe-71.png',
	'craft:bakery": "Padaria': 'https://maps.gstatic.com/mapfiles/place_api/icons/cafe-71.png',
	'amenity:pharmacy': 'https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png',
	'shop:chemist': 'https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png',
	'amenity:hospital': 'https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png',
	'amenity:library': 'https://maps.gstatic.com/mapfiles/place_api/icons/library-71.png',
	'shop:books': 'https://maps.gstatic.com/mapfiles/place_api/icons/library-71.png',
	'amenity:police': 'https://maps.gstatic.com/mapfiles/place_api/icons/police-71.png',
	'amenity:post_office': 'https://maps.gstatic.com/mapfiles/place_api/icons/post_office-71.png',
	'amenity:school': 'https://maps.gstatic.com/mapfiles/place_api/icons/school-71.png',
	'amenity:childcare': 'https://maps.gstatic.com/mapfiles/place_api/icons/baby-71.png',
	'amenity:university': 'https://maps.gstatic.com/mapfiles/place_api/icons/university-71.png',
	'amenity:restaurant': 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
	'amenity:fuel': 'https://maps.gstatic.com/mapfiles/place_api/icons/gas_station-71.png',
	'shop:beauty': 'https://maps.gstatic.com/mapfiles/place_api/icons/barber-71.png',
	'shop:convenience': 'https://maps.gstatic.com/mapfiles/place_api/icons/convenience-71.png',
	'amenity:nightclub': 'https://maps.gstatic.com/mapfiles/place_api/icons/convenience-71.png',
	'shop:pet': 'https://maps.gstatic.com/mapfiles/place_api/icons/pet-71.png',
	'shop:mall': 'https://maps.gstatic.com/mapfiles/place_api/icons/shopping-71.png',
	'shop:supermarket': 'https://maps.gstatic.com/mapfiles/place_api/icons/supermarket-71.png',
	'building:church': 'https://maps.gstatic.com/mapfiles/place_api/icons/worship_general-71.png',
	'building:stadium': 'https://maps.gstatic.com/mapfiles/place_api/icons/stadium-71.png',
	'leisure:stadium': 'https://maps.gstatic.com/mapfiles/place_api/icons/stadium-71.png',
	'leisure:fitness_centre': 'https://maps.gstatic.com/mapfiles/place_api/icons/fitness-71.png',
	'leisure:sports_centre': 'https://maps.gstatic.com/mapfiles/place_api/icons/baseball-71.png',
	'leisure:park': 'https://maps.gstatic.com/mapfiles/place_api/icons/mountain-71.png',
	'tourism:museum': 'https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png',
	'tourism:attraction': 'https://maps.gstatic.com/mapfiles/place_api/icons/monument-71.png',
	'amenity:grave_yard': 'https://maps.gstatic.com/mapfiles/place_api/icons/worship_christian-71.png',
	'landuse:cemetery': 'https://maps.gstatic.com/mapfiles/place_api/icons/worship_christian-71.png',
	'amenity:car_wash': 'https://maps.gstatic.com/mapfiles/place_api/icons/car_repair-71.png',
	'amenity:parking': 'https://maps.gstatic.com/mapfiles/place_api/icons/car_dealer-71.png'
}

try {

	let el = document.querySelector('.poi[data-lat]')
	if (el) {
		let lat = Number(el.dataset.lat)
		let lng = Number(el.dataset.lng)

		if (lat != 0 && lng != 0) {
			let position = {
				latitude: lat,
				longitude: lng
			}
			
			let options = {
				neighbors: false,
				precision: 5
			}
			
			ProximitySearch.search(position, options)
				.then(results => {
					if (results.length == 0) {
						return
					}

					let pontos = results.filter(ponto => ponto.name).map(ponto => {
						let distance = haversine({
							latitude: lat,
							longitude: lng
						}, {
							latitude: ponto.lat,
							longitude: ponto.lng
						})
						distance = Math.abs(Math.round(distance))
						return {
							...ponto,
							distance
						}
					})
					console.log({ pontos })
					pontos = pontos.filter(({ distance }) => {
						return distance <= 1000
					})

					if (pontos.length === 0) {
						return
					}

					pontos.sort((a,b) => {
						return a.distance < b.distance ? -1 : 1
					})
					
					pontos = pontos.slice(0, 20).map(ponto => {
						let tags = ponto.tags.split(',').filter(tag => {
							if (!icons[tag]) {
								return false
							}
							return true
						})
						let icon = 'https://maps.gstatic.com/mapfiles/place_api/icons/generic_business-71.png'
						if (tags.length != 0) {
							let [tag] = tags
							icon = icons[tag]
						}
						return `
							<li>
								<img src="${icon}" class="poi-image"/>
								${ ponto.name }
								-
								${ponto.distance}m
							</li>
						`
					}).join('')

					el.innerHTML = `
						<hr />
						<h3>Pontos de Interesse</h3>
						<ul class="arrow poi">
							${pontos}
						</ul>
					`
					/*
					for (let result of results) {
						let { id, name, tags, lat, lng } = result
						console.log({ id, name, tags, lat, lng })
					}
					*/
				})

		}


	}

	
} catch (error) {
	console.error(error)
}