import "@/lib/str.js";
import "@/lib/closest-polyfill";

const InitFiltro = async () => {
	const [Vue, FiltroHome] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Home/Filtro.vue").then(m => m.default)
	]);
	document.querySelectorAll("[name=formFiltros]").forEach(el => {
		new Vue({
			el: el,
			render: h => h(FiltroHome)
		});
	});
};

const InitSearch = async () => {
	const [Vue, Search] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Search.vue").then(m => m.default)
	]);
	new Vue({
		el: ".vue-search",
		render: h => h(Search)
	});
};

const InitDestaques = async () => {
	const [Vue, Destaques] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Destaques.vue").then(m => m.default)
	]);
	document
		.querySelectorAll(".imoveis-destaque[data-modalidade]")
		.forEach(el => {
			let finalidade =
				el.dataset.modalidade == "Venda" ? "venda" : "aluguel";

			new Vue({
				el: el.closest("div.columns"),
				render: h =>
					h(Destaques, {
						props: {
							finalidade
						}
					})
			});
		});
};

const InitFlags = async () => {
	const [Vue, Flags] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Flags.vue").then(m => m.default)
	]);
	document
		.querySelectorAll(".resultado-imoveis-flags[data-flags]")
		.forEach(el => {
			let flags = [];
			try {
				flags = el.dataset.flags || [];
				flags = JSON.parse(flags);
			} catch (err) {
				console.error(err);
				return;
			}

			new Vue({
				el,
				render: h =>
					h(Flags, {
						props: {
							flags
						}
					})
			});
		});
};

const InitFavoritos = async () => {
	const [Vue, Favoritos] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Favoritos.vue").then(m => m.default)
	]);
	document
		.querySelectorAll(".resultado-imoveis-favoritos")
		.forEach(el => {
			

			new Vue({
				el,
				render: h =>
					h(Favoritos)
			});
		});
};

const InitSemelhantes = async () => {
	const [Vue, Semelhantes] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Semelhantes.vue").then(m => m.default)
	]);
	document
		.querySelectorAll(".imoveis-semelhantes[data-modalidade]")
		.forEach(el => {
			let finalidade =
				el.dataset.modalidade == "Aluguel" ||
				el.dataset.modalidade == "aluguel"
					? "aluguel"
					: "venda";
			let tipo_id = el.dataset.tipo ;
			let valor = el.dataset.valor * 1.0;
			let dormitorios = el.dataset.dormitorios || [];
			let cidade = el.dataset.cidade;

			new Vue({
				el,
				render: h =>
					h(Semelhantes, {
						props: {
							finalidade,
							tipo_id,
							valor,
							dormitorios,
							cidade
						}
					})
			});
		});
};

const InitMedia = async () => {
	const [Vue, Media] = await Promise.all([
		import("vue").then(m => m.default),
		import("@/components/Media.vue").then(m => m.default)
	]);
	document.querySelectorAll(".vue-imovel-media[data-imovel]").forEach(el => {
		try {
			let imovel = JSON.parse(el.dataset.imovel);
			new Vue({
				el,
				render: h =>
					h(Media, {
						props: {
							imovel
						}
					})
			});
		} catch (err) {
			console.error(err);
		}
	});
};

const init = async () => {
	if (document.querySelector("[name=formFiltros]")) {
		InitFiltro();
	}

	if (document.querySelector(".vue-search")) {
		InitSearch();
	}

	if (document.querySelector(".imoveis-destaque[data-modalidade]")) {
		InitDestaques();
	}

	if (document.querySelector(".resultado-imoveis-flags[data-flags]")) {
		InitFlags();
	}

	if (document.querySelector(".resultado-imoveis-favoritos")) {
		InitFavoritos();
	}

	if (document.querySelector(".imoveis-semelhantes[data-modalidade]")) {
		InitSemelhantes();
	}

	if (document.querySelector(".vue-imovel-media[data-imovel]")) {
		InitMedia();
	}
};
init();
